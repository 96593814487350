import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute: React.FC = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const token = sessionStorage.getItem('access_token');

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get('https://api.robinhoodboechout.be/api/v1/auth/verify', options);

        setIsAuthorized(response.status === 200);
      } catch (error) {
        setIsAuthorized(false);
        sessionStorage.removeItem('access_token');
      }
    };

    if (token) {
      verifyToken();
    } else {
      setIsAuthorized(false);
      sessionStorage.removeItem('access_token');
    }
  }, [token]);

  if (isAuthorized === null) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? <Outlet /> : <Navigate to='/admin' replace />;
};

export default ProtectedRoute;