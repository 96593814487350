import Divider from '../../common/Divider';
import Heading from '../../common/Heading';


const GedragsCode = () => {

    return (
        <section className='py-4 flex flex-col items-center'>
            <div className='container md:w-[60%]'>
                <div className='p-8 whitespace-pre-wrap md:text-left md:items-left'>
                    <Heading text={'Ethisch verantwoorde sportbeoefening'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                    Iedereen die zich aansluit bij onze sportclub wil op een kwaliteitsvolle, betaalbare, aangename
                    en gezonde manier kunnen boogschieten. Echter kan dit enkel in een context waar aandacht
                    wordt besteed aan een aantal fundamentele ethische normen en waarden zoals respect en
                    verdraagzaamheid. Robin Hood Boechout schenkt de nodige aandacht aan het ethische aspect
                    van sporten.
                    <br />
                    <br />
                    <b>Maar wat is nu ethiek in de sport?</b>
                    <br />
                    <br />
                    <i>“Sporten op ethisch verantwoorde manier is sporten in overeenstemming met het geheel van
                    positieve waarden en normen en daarmee verband houdende maatregelen die eenieder in acht
                    moet nemen met het oog op de bewaring en bevordering van de individuele integriteit (fysieke,
                    psychische en seksuele), fair play en sociale integriteit (solidariteit, diversiteit en inclusie).”</i>
                    <br />
                    <br />
                    <b>Waarom een gedragscode?</b>
                    <br />
                    <br />
                    Wij richten ons tot de recreatieve sporter in al zijn diversiteit. Wij zijn er ons dan ook terdege
                    bewust van dat sport voor een deel van onze leden een doel op zich is, voor andere leden een
                    middel voor het verwezenlijken van andere doelstellingen. Wij bieden boogschieten aan op
                    zeer laagdrempelige wijze, in een aangename en ontspannen sfeer.
                    <br />
                    <br />
                    <b>Robin Hood Boechout</b> vindt het daarom belangrijk dat elke sporter zich, binnen de sportclub,
                    welkom en aanvaard voelt en met plezier en op zijn/haar eigen niveau op kwaliteitsvolle,
                    recreatieve wijze het boogschieten kan beoefenen.
                    <br />
                    <br />
                    Met deze gedragscode wensen wij onze sportclub en diens visie betreffende ethiek duidelijk
                    over te brengen en ten einde discussies betreffende ongewenst gedrag te vermijden. Er wordt
                    specifiek een sectie toegewijd aan het begrip ‘grensoverschrijdend gedrag in de sport’ en heel
                    specifiek aan ‘seksueel grensoverschrijdend gedrag in de sport’. 
                    <br />
                    <br />
                    Indien er vragen zijn over deze gedragscode, aarzel zeker en vast niet ons deze te stellen.
                    Wenst u hierover met iemand te praten, neem gerust contact op met de contactpersoon voor
                    integriteit in onze club Marleen Sanchez (<a className='text-primary hover:text-secondary' href='mailto:marleen.sanchez@robinhoodboechout.be'>marleen.sanchez@robinhoodboechout.be</a>).
                    <br />
                    <br />
                    Het bestuur
                    </p>
                    <br />
                    <Heading text={'Gedragscode'} light={false} subHeading={false} />
                    <Divider />
                    <p>voor (jeugd)sporters, lesgevers/trainers, begeleiders, ouders, bestuurders en supporters</p>
                    <br />
                    <Heading text={'(JEUGD)SPORTERS'} light={false} subHeading={true} />
                    <Divider />
                    <p><b><u>Omtrent houding:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Toon respect voor je trainer en begeleiders.</li>
                        <li className="list-disc">Toon respect voor je medespelers.
                        </li>
                        <li className="list-disc">Wees trots op je club.</li>
                        <li className="list-disc">Kom naar de club om samen plezier te maken, niet om je af te reageren.</li>
                        <li className="list-disc">Pesten, racisme, discriminatie, agressie in woorden of daden, intimidatie en fysiek
                        geweld worden absoluut niet geduld.</li>
                        <li className="list-disc">Focus op je groei en mogelijkheden, niet op je tekortkomingen.</li>
                        <li className="list-disc">Gebruik steeds een beleefd taalgebruik t.o.v. medespelers, scheidsrechters, trainers en
                        supporters.</li>
                        <li className="list-disc">Help je medesporters, die het wat moeilijker hebben waar nodig, en moedig hen aan.</li>
                        <li className="list-disc">Durf de trainer/jeugdcoördinator/contactpersoon integriteit aanspreken wanneer er
                        iets schort of wanneer je niet goed in je vel zit.</li>
                        <li className="list-disc">Neem enthousiast deel aan het verenigingsleven van je club en je team.</li>
                        <li className="list-disc">Toon respect voor de vrijwilligers die zich voor jou inzetten in hun vrije tijd</li>
                        <li className="list-disc">Maak schoon wat je vuil hebt gemaakt.</li>
                        <li className="list-disc">Neem geen dingen van een ander, dat is stelen.</li>
                    </ul>
                    <br />
                    <p><b><u>Omtrent de training:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Regelmatig en op tijd aanwezig zijn.</li>
                        <li className="list-disc">Help de trainer met het opruimen van het materiaal na de training.</li>
                        <li className="list-disc">Vraag uitleg aan de trainer als je iets niet begrijpt.</li>
                        <li className="list-disc">Geef je medespelers positieve kritiek, breek ze niet af wanneer ze een foutje maken.</li>
                        <li className="list-disc">Doe gemotiveerd mee aan alle oefeningen en geef je steeds 100%.</li>
                        <li className="list-disc">Draag zorg voor het clubmateriaal dat je mag huren/gebruiken.</li>
                    </ul>
                    <br />
                    <p><b><u>Omtrent de wedstrijd:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Onsportiviteit van een ander mag nooit een reden zijn om zelf onsportief te zijn.</li>
                        <li className="list-disc">Bekritiseer de beslissingen van de scheidsrechter niet.</li>
                        <li className="list-disc">Geef blijk van een sportieve attitude en een goede fair play houding naar alle partijen.
                            (medespelers, trainers, scheidsrechters, ouders, supporters, tegenstrevers,…)</li>
                        <li className="list-disc">Wens, bij verlies, de tegenstander proficiat met de glimlach.</li>
                        <li className="list-disc">Wees (bescheiden) blij met een overwinning, laat je niet ontmoedigen door een tegenslag.</li>
                        <li className="list-disc">Behandel je tegenstrever zoals je zelf behandeld zou willen worden.</li>
                        <li className="list-disc">Respecteer de spelregels en overtreed ze niet moedwillig.</li>
                    </ul>
                    <br />
                    <Heading text={'OUDERS / SUPPORTERS'} light={false} subHeading={true} />
                    <Divider />
                    <p><b><u>Hebben een voorbeeldfunctie:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Wees u bewust van uw voorbeeldfunctie.</li>
                        <li className="list-disc">Zorg ervoor dat uw gedrag sportief is.</li>
                        <li className="list-disc">Laat geen rommel achter.</li>
                        <li className="list-disc">Moedig uw kind aan de regels te volgen en respectvol te zijn.</li>
                        <li className="list-disc">Veroordeel elk gebruik van geweld.</li>
                    </ul>
                    <br />
                    <p><b><u>Dienen de sporters aan te moedigen:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Wees gul met lof voor inzet en prestatie.</li>
                        <li className="list-disc">Geef steeds positieve kritiek, breek sporters nooit af wanneer het eens wat minder loopt.</li>
                        <li className="list-disc">Maak een kind nooit belachelijk en scheld het niet uit als het een fout maakt.</li>
                        <li className="list-disc">Focus op de mogelijkheden van de sporters, schenk niet te veel aandacht aan hun tekortkomingen.</li>
                        <li className="list-disc">Vermijd het gebruik van grove taal, beledigingen of belagingen.</li>
                    </ul>
                    <br />
                    <p><b><u>Dienen positief betrokken te zijn:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Laat je mening horen op een constructieve manier.</li>
                        <li className="list-disc">Ken je plaats als ouder.</li>
                        <li className="list-disc">Respecteer de bestaande communicatiekanalen.</li>
                        <li className="list-disc">Gun jouw kind het plezier van de sport.</li>
                    </ul>
                    <br />
                    <p><b><u>Moeten de prestatie van hun sporter(s) kunnen relativeren:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Schat het talent van je sporter(s) naar waarde.</li>
                        <li className="list-disc">Leer je sporter dat je best doen even goed is als winnen.</li>
                        <li className="list-disc">Wees nederig in het verlies en bescheiden bij winst.</li>
                    </ul>
                    <br />
                    <Heading text={'TRAINER / LESGEVER'} light={false} subHeading={true} />
                    <Divider />
                    <p><b><u>Beschikt over de nodige sporttechnische capaciteiten:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Laat iedere sporter ontwikkelen op zijn niveau.</li>
                        <li className="list-disc">Differentieer waar mogelijk: geef trainingen die aangepast zijn aan de leeftijd, het
                        individueel ritme en de mogelijkheden.</li>
                        <li className="list-disc">Wees redelijk in je sportieve eisen.</li>
                        <li className="list-disc">Geef concrete en duidelijke tips aan je sporters.</li>
                        <li className="list-disc">Hou je uitleg eenvoudig. Pas je uitleg te allen tijde aan aan het niveau van je sporters.</li>
                        <li className="list-disc">Werk aan je eigen competentieniveau: school je regelmatig bij.</li>
                        <li className="list-disc">Werk met duidelijke afspraken en routine. Dit zorgt voor rust en vertrouwdheid bij je sporters.</li>
                    </ul>
                    <br />
                    <p><b><u>Behandelt elke sporter gelijkwaardig:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Toon belangstelling voor al je sporters.</li>
                        <li className="list-disc">Betrek elke sporter maximaal bij de training of wedstrijd.</li>
                        <li className="list-disc">Zorg voor een clubklimaat waarbinnen elke sporter wordt opgenomen.</li>
                        <li className="list-disc">Focus op de mogelijkheden van de sporters, schenk niet teveel aandacht aan hun tekortkomingen.</li>
                    </ul>
                    <br />
                    <p><b><u>Is zich bewust van zijn opvoedkundige taak:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Besteed naast het aanleren van de sport ook aandacht aan het gedrag van je sporters
                        tijdens de training.</li>
                        <li className="list-disc">Bewaak de fysieke en psychische integriteit van elke sporter.</li>
                        <li className="list-disc">Wees niet enkel verantwoordelijk voor uw eigen gedrag maar ook voor dat van uw
                        sporters, hun ouders en supporters.</li>
                        <li className="list-disc">Ontwikkel respect voor de vaardigheid van de tegenstander, voor de beslissingen van de
                        scheidsrechter en voor de trainer van de tegenstander, voor het materiaal en de
                        accommodatie.</li>
                        <li className="list-disc">Leer uw spelers dat de spelregels afspraken zijn waar niemand zich aan mag onttrekken;
                        leer hen sportief te zijn. Leer hen niet enkel om te winnen, maar leer hen ook een
                        waardig verliezer te zijn.</li>
                    </ul>
                    <br />
                    <p><b><u>Heeft een voorbeeldfunctie binnen de sportclub:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Keur verbaal en fysiek geweld af.</li>
                        <li className="list-disc">Wees een voorbeeld van Fair Play en Sportiviteit.</li>
                        <li className="list-disc">Heb oog voor de problematiek van je sporters.</li>
                    </ul>
                    <br />
                    <p><b><u>Toont betrokkenheid:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Wees op de hoogte van wat er ‘leeft’ in je groep.</li>
                        <li className="list-disc">Houd rekening met de leefwereld van je sporters.</li>
                        <li className="list-disc">Respecteer het recht op privacy.</li>
                        <li className="list-disc">Geef aandacht aan de bezorgdheden van ouders en begeleiders.</li>
                        <li className="list-disc">Toon bereidheid tot samenwerking en communicatie met ouders, andere trainers, bestuurders,…</li>
                    </ul>
                    <br />
                    <p><b><u>Zorgt voor een positieve sfeer:</u></b></p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Laat het spelplezier primeren op het resultaat van je sporters.</li>
                        <li className="list-disc">Sporten moet in de eerste plaats plezierig zijn; zorg voor voldoende spelelementen in je training.</li>
                        <li className="list-disc">Behandel je sporters zoals je zelf behandeld zou willen worden.</li>
                        <li className="list-disc">Communiceer positief: ook non-verbaal!</li>
                        <li className="list-disc">Maak van de sportclub een plaats waar iedereen welkom is en zich thuis voelt.</li>
                        <li className="list-disc">Doe je best om een veilige omgeving te creëren voor alle sporters.</li>
                    </ul>
                    <br />
                    <Heading text={'Neen tegen grensoverschrijdend gedrag!'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                    Iedereen in <b>Robin Hood Boechout</b> moet zich veilig voelen. Daarom is het voor ons belangrijk
                    elke vorm van ongewenst gedrag, zoals seksueel grensoverschrijdend gedrag, beledigingen,
                    pesten, discriminatie, bedreiging en mishandeling zoveel mogelijk te voorkomen. En daarnaast
                    moet duidelijk zijn hoe te handelen wanneer zich een incident voordoet. Wij vinden het
                    belangrijk om (jeugd)sporters en diens begeleiders daarin bij te staan.
                    <br />
                    <br />
                    Wanneer je te maken krijgt met grensoverschrijdend gedrag is dat een teken dat de
                    (sport)omgeving niet veilig meer is. Het kan veel emoties en vragen oproepen, ook als je er
                    niet zelf het slachtoffer van bent, maar het ziet gebeuren. Blijf er niet mee rondlopen!
                    Signaleer het en praat erover met iemand in wie je vertrouwen hebt. Direct signaleren en het
                    in de openbaarheid brengen van grensoverschrijdend gedrag kan herhaling of het verergeren
                    van de situatie voorkomen.
                    <br />
                    <br />
                    Als je liever met een onbekende wilt praten, kan je terecht bij onze vertrouwenspersoon (VCP)
                    Marleen Sanchez (<a className='text-primary hover:text-secondary' href='mailto:marleen.sanchez@robinhoodboechout.be'>marleen.sanchez@robinhoodboechout.be</a>) of met de contactpersoon van
                    Boogsport Vlaanderen (<a className='text-primary hover:text-secondary' href='mailto:info@boogsportvlaanderen.be'>info@boogsportvlaanderen.be</a> of 014869387). Deze personen zijn
                    opgeleid om dergelijke uiteenlopende situaties in te schatten en de bijhorende procedures te
                    starten en begeleiden.
                    <br />
                    <br />
                    <b>Robin Hood Boechout</b> neemt seksueel grensoverschrijdend gedrag heel ernstig. Deze regels zijn
                    gemaakt om de risico's op ongewenst gedrag te verkleinen ende ze fungeren als toetssteen voor
                    het gedrag van onze begeleiders en (jeugd)sporters in concrete situaties. 
                    </p>
                    <br />
                    <Heading text={'Gedragsregels seksueel grensoverschrijdend gedrag'} light={false} subHeading={true} />
                    <Divider />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Eenieder zal steeds met respect handelen.</li>
                        <li className='list-disc'>De sportbegeleider staat steeds in voor een omgeving die voor de sporter veilig aanvoelt.</li>
                        <li className='list-disc'>Men tast iemand nooit aan in zijn waardigheid én dringt nooit verder in in het privéleven
                        dan waarvoor deze persoon zelf toestemming geeft..
                        </li>
                        <li className='list-disc'>Eenieder onthoudt zich van elke vorm van (machts)misbruik of seksueel
                        grensoverschrijdend gedrag.</li>
                        <li className='list-disc'>Eenieder mag de sporter niet op een zodanige ongewenste wijze aanraken dat de sporter
                        en/of de sportbegeleider deze aanraking als seksueel ervaren.</li>
                        <li className='list-disc'>Eenieder onthoudt zich van seksueel getinte verbale opmerkingen.</li>
                        <li className='list-disc'>Indien eenieder op de hoogte is van seksueel grensoverschrijdend gedrag heeft deze
                        persoon de plicht hier melding van te maken, zodat zo snel mogelijk een einde aan het
                        misbruik kan gesteld worden.</li>
                    </ul>
                    <br />
                    <Heading text={'Sancties'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                    Interne sancties kunnen na overleg met het bestuur opgelegd worden. Het bestuur stelt hierbij
                    het collectieve belang van haar leden boven eventueel individueel belang van de
                    (jeugd)sporter, begeleider, ouder of supporter.
                    <br />
                    <br />
                    Een aantal van de sancties zijn voor de vereniging extern, denk hierbij aan een vervolging voor
                    overtreding uit het Wetboek van Strafrecht. 
                    </p>
                </div>
            </div>
        </section>
    );
};

export default GedragsCode;