import { PartialBlock } from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';
import { useCreateBlockNote } from '@blocknote/react';
import React from 'react';
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";

interface EditorProps {
    content: PartialBlock[];
    isEditable: boolean;
}

const Editor: React.FC<EditorProps> = ({ content, isEditable }) => {
    const theme = {
        colors: {
          editor: {
            text: "text-primary",
            background: "bg-gray-50",
          },
        }
      };

      const editor = useCreateBlockNote({
        initialContent: content.length > 0 ? content : [{
            type: 'paragraph',
            content: 'Start writing here...'
        }] as PartialBlock[],
    });

    return (
        <BlockNoteView editor={editor} editable={isEditable} theme={theme} />
    );
};

export default Editor;