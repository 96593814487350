import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Sidebar, SidebarItem} from './layout/Sidebar';
import { FaTh, FaNewspaper, FaCalendarAlt, FaCommentAlt, FaImages, FaUserShield, FaUsers, FaEuroSign, FaDumbbell, FaScroll } from 'react-icons/fa';


const ProtectedLayout: React.FC = () => {
  let location = useLocation().pathname;
  return (
    <div className='flex'>
      <Sidebar>
        <SidebarItem icon={<FaTh size={20} />} text='Dashboard' to='/admin/dashboard' active={location.includes('/dashboard') ? true : false} />
        <hr className='my-3' />
        <SidebarItem icon={<FaCommentAlt size={20} />} text='Nieuwsberichten' to='/admin/nieuwsberichten' active={location.includes('/nieuwsberichten') ? true : false} />
        <SidebarItem icon={<FaNewspaper size={20} />} text='Nieuwsbrief' to='/admin/nieuwsbrief' active={location.includes('/nieuwsbrief') ? true : false} />
        <hr className='my-3' />
        <SidebarItem icon={<FaUsers size={20} />} text='Bestuur' to='/admin/bestuur' active={location.includes('/bestuur') ? true : false} />
        <SidebarItem icon={<FaDumbbell size={20} />} text='Trainingen' to='/admin/trainingen' active={location.includes('/trainingen') ? true : false} />
        <SidebarItem icon={<FaCalendarAlt size={20} />} text='Evenementen' to='/admin/evenementen' active={location.includes('/evenementen') ? true : false} />
        <SidebarItem icon={<FaEuroSign size={20} />} text='Lidgeld' to='/admin/lidgeld' active={location.includes('/lidgeld') ? true : false} />
        <hr className='my-3' />
        <SidebarItem icon={<FaImages size={20} />} text='Fotos' to='/admin/fotos' active={location.includes('/fotos') ? true : false} />
        <hr className='my-3' />
        <SidebarItem icon={<FaUserShield size={20} />} text='Gebruikers' to='/admin/gebruikers' active={location.includes('/gebruikers') ? true : false} />
        <SidebarItem icon={<FaScroll size={20} />} text='Legal' to='/admin/legal' active={location.includes('/legal') ? true : false}/>
        <hr className='my-3' />
      </Sidebar>
      {/* MAIN CONTENT */}
      <div className='flex-grow p-6 overflow-hidden'>
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;
