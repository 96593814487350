import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';

import Logo from '../../../assets/images/logo-white-lg.png';
import AboutImage from '../../../assets/images/about.jpg';

const About = () => {

    return (
        <section className='py-16 bg-fixed bg-cover bg-center bg-no-repeat' style={{ backgroundImage: `url(${AboutImage})` }}>
            <div className='mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10 pt-8 w-3/4'>
                <div className='w-96 hidden md:block'>
                    <img src={Logo} alt='RHB-Logo' />
                </div>
                <div className='w-full flex md:block flex-col text-center md:text-left md:items-start items-center'>
                    <SectionTitle text={'Over Ons'} light={true} />
                    <Divider />
                    <p className='text-primary-text'>
                        Onze vereniging is in 1952 opgericht. Na omzwervingen in Mortsel en Hove zijn we in 1972 geland in Boechout. Hier bieden we de mogelijkheid aan de handboogschutter om zijn sport en passie te beoefenen. Zowel binnen als buiten, voor jong en oud, alle handboogdisciplines inclusief mogelijkheid tot luchtdoelschieten.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;