import React from 'react';

interface HeadingProps {
    text: string;
    light: boolean;
    subHeading: boolean;
}

const Heading: React.FC<HeadingProps> = ({ text, light, subHeading }) => {
    return (
        <h1 className={`${light ? 'text-primary-text' : 'text-black'} font-bold ${subHeading ? 'md:text-xl': 'md:text-2xl text-xl'}  mx-auto`}>{text}</h1>
    );
};

export default Heading;