import React from 'react';

interface InputProps {
    name: string;
    type: string;
    required: boolean;
    placeholder: string;
    value?: string;
    onChange: (name: string, value: string) => void;
}

const InputField: React.FC<InputProps> = ({ name, type, required, placeholder, value, onChange }) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(name, e.target.value); // Pass value and name back to parent
      };
    return (
        <input name={name} type={type} className='bg-gray-100 w-full text-sm text-gray-800 px-4 py-3.5 rounded-md outline-accent focus:bg-transparent' placeholder={placeholder} required={required} value={value} onChange={handleInputChange}/>
    );
};

export default InputField;