'use client'
import { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Button from '../common/Button';

import Logo from '../../assets/images/logo-white.png';


const NAV_ITEMS = [
    { name: 'Home', path: '/' },
    { name: 'Trainingen', path: '/trainingen' },
    { name: 'Luchtdoelschieten', path: '/luchtdoelschieten' },
    { name: 'Nieuws', path: '/nieuws' },
    { name: 'Agenda', path: '/agenda' }
]

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <>
            <nav className=''>
                <div className='max-w-6xl mx-auto px-4'>
                    <div className='flex justify-between'>
                        <div className='flex space-x-7'>
                            <div className='flex items-center py-4 px-2'>
                                {/* Logo */}
                                <a href='/'>
                                    <img src={Logo} alt='Logo' className='w-36 -mr-2' />
                                </a>
                            </div>
                        </div>
                        {/* Primary Navbar items */}
                        <div className='hidden md:flex items-center space-x-1'>
                            {NAV_ITEMS.map(item => (
                                <a href={item.path} key={item.name} className='py-4 px-2 text-primary-text font-bold border-b-4 border-transparent hover:border-white transition ease-in-out'>{item.name}</a>
                            ))}
                        </div>
                        {/* CTA Buttons */}
                        <div className='hidden md:flex items-center text-primary-text space-x-1'>
                            <Button text='Lid Worden' link='/lidgelden' />
                        </div>
                        {/* Mobile menu button */}
                        <div className='md:hidden flex items-center'>
                            <button className='z-50 outline-none mobile-menu-button text-primary-text' onClick={toggleMenu}>
                                {isOpen ? <FaTimes className='text-4xl text-black' /> : <FaBars className='text-4xl' />}
                            </button>
                        </div>
                    </div>
                </div>
                {/* Mobile Menu */}
                <div className={`${isOpen ? 'block' : 'hidden'} pt-40 fixed w-full top-0 h-full z-40 md:hidden text-center font-bold bg-white`}>
                    <a href='/' className='block text-sm px-2 py-4 text-gray-700 hover:bg-gray-200 transition duration-300'>Home</a>
                    <a href='/trainingen' className='block text-sm px-2 py-4 text-gray-700 hover:bg-gray-200 transition duration-300'>Trainingen</a>
                    <a href='/luchtdoelschieten' className='block text-sm px-2 py-4 text-gray-700 hover:bg-gray-200 transition duration-300'>Luchtdoelschieten</a>
                    <a href='/nieuws' className='block text-sm px-2 py-4 text-gray-700 hover:bg-gray-200 transition duration-300'>Nieuws</a>
                    <a href='/agenda' className='block text-sm px-2 py-4 text-gray-700 hover:bg-gray-200 transition duration-300'>Agenda</a>
                    <div className='max-w-80 mx-auto p-6'>
                        <Button text='Lid Worden' link='/lidgelden' />
                    </div>
                </div>
            </nav >

        </>
    );
};

export default Navbar;