import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';

const images = [
    "https://robinhoodboechout.be/assets/img/luchtdoelschieten/1.jpg",
    "https://robinhoodboechout.be/assets/img/luchtdoelschieten/2.jpg",
    "https://robinhoodboechout.be/assets/img/luchtdoelschieten/3.jpg",
    "https://robinhoodboechout.be/assets/img/luchtdoelschieten/4.jpg"
]

export default function Gallery() {
    return (
        <>
            <section className='mx-auto py-16 flex flex-col items-center w-3/4'>
                <SectionTitle text={'Foto Galerij'} light={false} />
                <Divider />
                <div className='columns-1 sm:columns-2 lg:columns-4 py-10 md:py-10 gap-4'>
                    {images.map((src, index) => (
                        <div key={index} className='mb-4 break-inside-avoid'>
                            <img alt='' src={src} className='w-full object-cover rounded-lg' />
                        </div>
                    ))}
                </div>
            </section >
        </>
    );
}